a:link, a:visited {
  color:  gray;
  text-decoration: underline;
}

a:hover, a:active {
  color:  #c038ff;
  text-decoration: underline;
}

.SmallSiteIcon {
  display: inline-block;
  margin-right: 20px;
}

.SmallSiteIcon img {
  width:  80px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.App-header {
  background-color: #white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.FullCard {
  padding: 30px;
  color:  #9915d6;
  min-height: 400px;
}

.FullCard.NoPadding {
  padding:  0;
}

.ImageCard {
  background-size: cover;
  background-position: 50% 50%;
  min-height: 400px;
}

.FullCard.Purple {
  color:  white;
  background: #9915d6;
}

.FullCard.Purple a:link, .FullCard.Purple a:visited {
  color:  white;
  text-decoration: underline;
}

.FullCard.Purple a:hover, .FullCard.Purple a:active {
  color:  #c038ff;
  text-decoration: underline;
}

.FullCard.DarkBG {
  color:  white;
}

.App-link {
  color: #61dafb;
}

.topNav {
  display: block;
  background: #9915d6;
  margin:  0;
  padding:  0 0 0 10px;
  line-height: 50px;
  height:  50px;
  border-collapse: collapse;
}

.topNav > li {
  display: inline-block;
}

.topNav > li > a:link, .topNav > li > a:visited {
  text-decoration: none;
  color:  white;
  margin-right: 15px;
}

.topNav > li > a:hover {
  text-decoration: underline;
  color:  white;
}

.MediaList .MediaItem .metadata {
  color:  gray;
  font-size: 0.5em;
}

.MediaList .MediaItem .metadata > span {
  display: block;
}


.MediaList .MediaItem .image {
  max-width: 200px;
}